// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdsBlock_v96\\+j{background-repeat:no-repeat;background-size:contain;border-radius:2rem;display:flex;flex-direction:column;height:100%;justify-content:space-between;width:100%}.AdsBlock_v96\\+j,.contentWrapper_AoJoG{position:relative;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"AdsBlock": "AdsBlock_v96+j",
	"contentWrapper": "contentWrapper_AoJoG"
};
module.exports = ___CSS_LOADER_EXPORT___;
