// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentBlockHeader_duymM{display:flex;flex-direction:column;justify-content:space-between;margin-bottom:4rem}@media(max-width:767.98px){.ContentBlockHeader_duymM{margin-bottom:2.4rem}}.row_IruUh{align-items:center;display:flex;justify-content:space-between}.title_uzLVz{font-size:2.8rem;font-weight:500;letter-spacing:-.112rem;line-height:3.08rem;max-width:74.6rem}.title_uzLVz._link_dyXk\\+{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"ContentBlockHeader": "ContentBlockHeader_duymM",
	"row": "row_IruUh",
	"title": "title_uzLVz",
	"_link": "_link_dyXk+"
};
module.exports = ___CSS_LOADER_EXPORT___;
