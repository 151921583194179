


export default {
    name: 'ContentBlockHeader',

    props: {
        title: {
            type: String,
            default: null,
        },

        /**
         * Поле с ссылкой для заголовка
         */
        link: {
            type: String,
            default: '',
        },
    },

    computed: {
        linkComponent() {
            return this.link ? 'n-link' : 'h2';
        },

        linkAttrs() {
            return this.linkComponent === 'n-link'
                ? {
                    to: this.link,
                }
                : {};
        },

        classListTitle() {
            return {
                [this.$style._link]: this.linkComponent === 'n-link',
            };
        },
    },
};
