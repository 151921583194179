
import VButtonNew from 'portal/components/ui/button/VButtonNew.vue';

export default {
    name: 'ContentBlockHeaderButton',
    components: { VButtonNew },
    props: {
        buttonText: {
            type: String,
            default: null,
        },

        buttonLink: {
            type: String,
            default: null,
        },

        color: {
            type: String,
            default: null,
        },

        size: {
            type: String,
            default: 'small',
        },

        tag: {
            type: String,
            default: 'a',
        },

        target: {
            type: String,
            default: null,
        },

    },


};
