
import VIcon from '~/components/ui/icon/VIcon';
import VButtonNew from 'portal/components/ui/button/VButtonNew.vue';
import VImage from '@@/common/components/ui/images/VImage';

const ADS_LABEL = 'Реклама';

export default {
    name: 'AdsCard',
    components: {
        VButtonNew,
        VIcon,
        VImage,
    },

    props: {
        buttonText: {
            type: String,
            default: 'Подробнее',
        },

        link: {
            type: String,
            default: '',
        },

        hasLabel: {
            type: Boolean,
            default: true,
        },

        buttonColor: {
            type: String,
            default: '',
        },

        buttonTextColor: {
            type: String,
            default: '',
        },

        buttonPlace: {
            type: String,
            default: 'right',
            validator: value => ['', 'right', 'left', 'full'].includes(value),
        },

        image: {
            type: String,
            default: '',
        },

        imageMobile: {
            type: String,
            default: '',
        },

        small: {
            type: Boolean,
            default: false,
        },

        nonLazyImage: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ADS_LABEL,
        };
    },

    computed: {
        classes() {
            return [{
                [this.$style['_small']]: this.small,
                [this.$style['_btnRight']]: this.buttonPlace === 'right' || this.buttonPlace === '',
                [this.$style['_btnFull']]: this.buttonPlace === 'full',
            }];
        },

        currentBackgroundImage() {
            return this.$deviceIs.mobile && this.imageMobile ? this.imageMobile : this.image;
        },
    },
};
