
import ContentBlockWrapperWithHeader from 'portal/components/common/content-block/ContentBlockWrapperWithHeader.vue';
import AdsCard from './AdsCard.vue';

export default {
    name: 'AdsBlock',
    components: {
        ContentBlockWrapperWithHeader,
        AdsCard,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        partner: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '#000',
        },

        image: {
            type: String,
            default: '',
        },

        imageMobile: {
            type: String,
            default: '',
        },

        buttonColor: {
            type: String,
            default: '#000',
        },

        buttonTextColor: {
            type: String,
            default: '#fff',
        },

        link: {
            type: String,
            default: null,
        },

        hasLabel: {
            type: Boolean,
            default: true,
        },

        buttonText: {
            type: String,
            default: 'Подробнее',
        },

        buttonPlace: {
            type: String,
            default: 'left',
        },

        logo: {
            type: String,
            default: '',
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },

    },

};
